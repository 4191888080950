.adjuvant-for-sl {
  font-family: Arial, sans-serif;
  margin: 1rem;
  background-image: url("../../../assets/images/Ellipse 21.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 25px;
  padding: 1.5rem;
  min-height: 300px;

  .adjuvant-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }

  .sc-container {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 25px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    .sc-title {
      font-size: clamp(1.5rem, 4vw, 1.8rem);
      font-weight: 700;
      background: linear-gradient(90deg, #097dc6, #51aae2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 1rem;
    }

    .sc-para {
      font-size: clamp(0.9rem, 2.5vw, 1rem);
      line-height: 1.6;
      color: #34495e;
      margin: 0;
    }

    .sc-list {
      font-size: clamp(0.9rem, 2.5vw, 1rem);
      line-height: 1.6;
      color: #34495e;
      list-style-type: disc;
      padding-left: 1.5rem;
      margin: 0;

      li {
        margin-bottom: 0.75rem;
      }
    }
  }

  // Tablet and below
  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin: 0.5rem;

    .sc-container {
      padding: 1rem;
      margin-bottom: 1rem;

      .sc-title {
        margin-bottom: 0.75rem;
      }
    }
  }

  // Mobile
  @media screen and (max-width: 480px) {
    padding: 0.75rem;

    .sc-container {
      padding: 0.75rem;

      .sc-list {
        padding-left: 1rem;
      }
    }
  }
}
