.fensil-360 {
  font-family: Arial, sans-serif;
  padding: 1rem 3rem;

  &__header {
    text-align: center;
    background-color: #f0f8ff;
    padding: 20px;

    h1 {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.9rem;
      background: linear-gradient(88.23deg, #097dc6 36.18%, #51aae2 115.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      text-align: center;
      width: fit-content;
      margin: auto;
    }

    h2 {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2rem;
      background: linear-gradient(88.23deg, #097dc6 36.18%, #51aae2 115.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      text-align: center;
      width: fit-content;
      margin: auto;
    }

    p {
      font-size: 16px;
    }
  }

  .bg-for {
    padding: 2rem 1.5rem;
    background-image: url("../../assets/images/Ellipse\ 21.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
    position: relative;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 800px) {
      flex-direction: column;
      padding: 3rem 2rem;
    }
    &::before {
      content: " ";
      z-index: 1;
      height: 90%;
      width: 96%;
      position: absolute;
      background: white;
      border-radius: 25px;
    }
  }
  .intro {
    align-items: center;
    justify-content: space-between;
    .content {
      width: 60%;
    }
    .img-section {
      background: url("../../assets/images/Ellipse\ 21.png");
      border-radius: 25px 0 0 25px;
      padding: 10px 10px 10px 2.5rem;

      img {
        border-radius: 15px;
        height: 300px;
      }
    }
  }

  &__about,
  &__features,
  &__key-features,
  &__soil-wetter,
  &__properties {
    margin: 20px 0;
    z-index: 2;

    h2 {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2rem;
      background: linear-gradient(88.23deg, #097dc6 36.18%, #51aae2 115.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      text-align: center;
      width: fit-content;
      margin: auto;
      margin-bottom: 10px;
    }

    ul {
      margin-left: 20px;
      width: 90%;

      li {
        margin-bottom: 5px;
        font-size: 15px;
        list-style-type: decimal;
      }
    }

    p {
      margin-bottom: 10px;
    }
  }

  &__footer {
    text-align: center;
    background-color: #eaf7ff;
    padding: 15px;

    a {
      color: #0056b3;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: "800px") {
  .fensil-360 {
    padding: 1.2rem;

    &__header {
      padding: 5px;

      h1 {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }

      h2 {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }

      p {
        font-size: 16px;
      }
    }

    .bg-for {
      padding: 1rem;
      &::before {
        content: " ";
        z-index: 1;
        height: 99%;
        width: 98%;
      }
    }
    .intro {
      flex-direction: column;
      .content {
        width: 100%;
      }
      .img-section {
        border-radius: 25px;
        padding: 1rem;
        img {
          border-radius: 25px;
          height: 250px;
        }
      }
    }

    &__about,
    &__features,
    &__key-features,
    &__soil-wetter,
    &__properties {
      margin: 20px 0;
      z-index: 2;

      h2 {
        font-weight: 700;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }

      ul {
        margin-left: 20px;
        width: 90%;

        li {
          margin-bottom: 5px;
          font-size: 15px;
          list-style-type: decimal;
        }
      }

      p {
        margin-bottom: 10px;
      }
    }

    &__footer {
      text-align: center;
      background-color: #eaf7ff;
      padding: 15px;

      a {
        color: #0056b3;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
