.JobApplyForm {
  max-width: 400px;
  margin: 0 auto;
  padding: 40px 40px;
  background-color: var(--bg-tertiary);
  border: 1px solid #dddddd00;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  .icon {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
  }

  h5 {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--bg-primary);
    span {
      color: var(--bg-secondary);
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .input-box {
    margin-bottom: 0.3rem;

    label {
      display: block;
      font-size: 1rem;
      font-weight: 600;
      color: #333;
    }

    input[type="text"],
    input[type="email"],
    input[type="file"] {
      width: 100%;
      height: 40px;
      padding: 10px;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 7px;
      transition: border-color 0.3s ease;
      box-sizing: border-box;

      &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
      }
    }
    input::placeholder {
      font-weight: 14px;
      font-weight: 400;
      //   color: #1E1E1E;
    }

    .fileupload {
      background: white;
      font-size: 14px;
      padding-bottom: 2rem;
      font-weight: 400;
    }
  }

  .otherDet {
    margin-bottom: 20px;

    textarea {
      width: 100%;
      padding: 10px;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 7px;
      resize: vertical;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #007bff;
        outline: none;
        box-shadow: 0 0 4px rgba(0, 123, 255, 0.3);
      }
    }
  }

  button[type="submit"] {
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: var(--bg-primary);
    background-color: var(--bg-secondary);
    border: none;
    border-radius: 9px;
    margin-top: -20px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: #0056b3;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

@media (max-width: 767px) {
  .JobApplyForm {
    width: 85%;
    margin: auto;
  }
}
