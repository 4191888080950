.botanical-products {
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .bp-head {
    background: linear-gradient(90deg, #097dc6, #51aae2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 2px solid #097dc6;
    padding-bottom: 10px;
    font-size: 1.8rem;
    line-height: 1.8rem;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .pb-title {
    background: linear-gradient(90deg, #097dc6, #51aae2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .product-title {
    color: #51aae2;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .bp-para {
    color: #131313a6;
  }

  ul {
    padding-left: 20px;
    margin-top: 10px;

    li {
      list-style-type: disc;
      color: #131313a6;
      line-height: normal;
      list-style: decimal;
    }
  }
  .product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .product {
      width: 48%;
      border-left: 4px solid #51aae2;
      padding-left: 15px;
      margin-bottom: 20px;
      background: #eef5f9;
      border-radius: 5px;
    }

    .product h3 {
      margin: 0;
    }

    .product p {
      margin: 5px 0 0;
    }
  }
}

@media (max-width: 767px) {
  .botanical-products {
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .bp-head {
      font-size: 1.3rem;

    }

    .pb-title {
     font-size: 1.3rem;
    }

    .product-title {
      color: #51aae2;
      margin-top: 20px;
      font-size: 1.2rem;
      font-weight: 500;
    }

    .bp-para {
      font-size: 14px;
      line-height: normal;
    }

    ul {
   

      li {
        font-size: 14px;
        line-height: normal;
      }
    }
    .product-container {
      flex-direction: column;
      .product {
        width: 100%;
      }

      .product h3 {
        margin: 0;
      }

      .product p {
        margin: 5px 0 0;
        font-size: 14px;
        line-height: normal;
      }
    }
  }
}
