.tell-container {
  overflow: hidden;
  position: relative;
  background: white;
  border: 2px solid rgb(255, 255, 255);

  .tell-us {
    height: 150px;
    width: 90%;
    margin: 0 auto;
    padding: 6rem 5rem;
    border-radius: 20px;
    background-image: url("../../../assets/images/home-square-gray.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 5px 10px 20px var(--light-shadow);

    .left-container {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 3;

      .heading {
        font-weight: 300;
        color: var(--text-secondary);
      }

      .subheading {
        font-weight: 500;
        color: var(--text-secondary);

        span {
          -webkit-background-clip: text;
          color: transparent;
        }
      }
    }

    .right-container {
      position: relative;
      z-index: 3;
      // width: 50%;
      display: flex;
      flex-direction: row;
      gap: 1.5rem;

      .search-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 30px;
        border: 1px solid var(--border);
        background: var(--bg-light);
        padding-right: 10px;
        border: 1px solid black;
        overflow: hidden;


        .input-box {
          color: black;
          padding: 12px 0px 12px 25px;
          border-radius: 30px;
          font-size: 1rem;
          width: 220px;
          position: relative;
          padding-right: 10px !important;
          // background: transparent !important;
          background: var(--bg-light);
          overflow: hidden;


          .option {
            // background: transparent !important;
            background: var(--bg-light);
            margin-top: -10px !important;

          }
        }
      }

      .search-icon-container {
        cursor: pointer;
        background: var(--bg-light);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        padding: 10px;
        box-shadow: 3px 3px 10px var(--light-shadow);
      }
    }

    .search-btn {
      display: none;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(11, 11, 11, 0.9);
      z-index: 2;
      border-radius: 20px;
    }
  }
}

@media (max-width: 1350px) {
  .tell-container {
    .tell-us {
      padding: 6rem 3rem;

      .left-container {
        .heading,
        .subheading {
          font-size: 2.3rem;
        }
      }

      .right-container {
        gap: 1rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .tell-container {
    .tell-us {
      padding: 7rem 3rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;

      .left-container {
        .heading,
        .subheading {
          font-size: 2.3rem;
        }
      }

      .right-container {
        gap: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .tell-container {
    .tell-us {
      padding: 2rem;
      height: fit-content;

      .right-container {
        gap: 10px;
        flex-wrap: wrap;

        .search-box {
          padding-right: 15px;

          .input-box {
            padding-left: 20px;
            width: 200px;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

@media (max-width: 670px) {
  .tell-container {
    .tell-us {
      .right-container {
        width: 100%;
        justify-content: space-between;
        gap: 1.3rem;

        .search-icon-container {
          display: none;
        }
      }

      .search-btn {
        display: flex;
        align-self: flex-end;
        border-radius: 7px;
        padding: 10px 2.5rem;
        box-shadow: none;
        background: var(--bg-primary);
        z-index: 9;

        .search-text {
          font-size: 1rem;
          font-weight: 500;
          color: var(--text-grey);
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .tell-container {
    .tell-us {
      .left-container {
        .heading,
        .subheading {
          font-size: 2rem;
        }
      }

      .right-container {
        gap: 1rem;

        .search-box {
          width: 100%;

          .input-box {
            width: 100%;
          }
        }
      }

      .search-btn {
        margin-top: 10px;
        align-self: center;

        .search-text {
          font-size: 1rem;
          font-weight: 500;
          color: var(--text-grey);
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .tell-container {
    .tell-us {
      padding: 1.3rem;

      .left-container {
        .heading,
        .subheading {
          font-size: 1.5rem;
        }
      }

      .search-btn {
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        background: var(--primary-linear-gradient);

        .search-text {
          font-size: 1rem;
          font-weight: 500;
          color: var(--text-grey);
          color: white;
        }
      }
    }
  }
}
