.event-head {
  background: linear-gradient(
    250.78deg,
    rgb(85, 179, 238) 2.75%,
    rgb(6, 132, 146) 100.17%
  );
  -webkit-background-clip: text;
  color: transparent;
  width: fit-content;
  font-weight: bold;
  margin: auto;
}
.ev {
  .HeadingOfEvents {
    width: 100%;
    text-align: center;
    margin: 1rem 0rem;
  }
  .accordion {
    padding: 1rem;

    .accordion-item {
      border: 1px solid #ddd;
      border-radius: 8px;
      margin-bottom: 1px;
      background-color: #f9f9f9;

      .accordion-header {
        padding: 0;
        margin: 0;
        border: 0;
        // background-color: #007bff;
        border-radius: 8px 8px 0 0;

        .accordion-button {
          background-color: transparent !important;
          color: rgb(5, 5, 5);
          background: linear-gradient(
            250.78deg,
            rgb(85, 179, 238) 2.75%,
            rgb(6, 132, 146) 100.17%
          );
          -webkit-background-clip: text;
          color: transparent;
          width: fit-content;
          font-weight: bold;

          font-size: 16px;
          font-weight: bold;
          text-align: left;
          padding: 15px;
          width: 100%;
          border: none;
          border-radius: 8px 8px 0 0;

          &:focus {
            box-shadow: none;
          }

          &.collapsed {
            background-color: #0056b3;
          }

          &:hover {
            background-color: #0056b3;
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          padding: 20px;
          background-color: white;
          border-top: 1px solid #ddd;
          color: #333;
          font-size: 14px;
          .subsection {
            .enventTitle:nth-child(1) {
              margin-top: 50px;
            }
            .image-carousel {
              padding: 10px 0;
              .image-container {
                height: 250px;
                // width: 200px;
                border: 4px solid rgb(255, 255, 255);
                border-radius: 8px;
                overflow: hidden;
                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }
            }
            .sub-content {
              color: black;
              line-height: 19px;
              font-size: 16px;
              text-align: justify;
            }
          }
        }

        .related-products {
          margin: 0;
          padding: 0;
          .all-related-products {
            .trending-cards {
              height: 100%;
              width: 95%;
              margin: auto;
              @media (max-width: 1068px) {
                width: 100%;
              }
            }
          }
          @media (max-width: 370px) {
            .heading-section {
              .heading {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    // Responsive Design
    @media (max-width: 768px) {
      .accordion-item {
        margin-bottom: 0.5rem;

        .accordion-header {
          .accordion-button {
            font-size: 14px;
            padding: 12px;
          }
        }
      }
    }
  }
}
.custom-next {
  cursor: pointer;
  position: absolute;
  right: -1%;
  bottom: 48%;
  font-size: 1.1rem;
}
.custom-prev {
  cursor: pointer;
  font-size: 1.1rem;

  position: absolute;
  left: -1.22%;
  bottom: 48%;
}

//
@media (max-width: 1054px) {
.custom-next {
  cursor: pointer;
  position: absolute;
  right: -2%;
  bottom: 48%;
  font-size: 1.1rem;
}
.custom-prev {
  cursor: pointer;
  font-size: 1.1rem;

  position: absolute;
  left: -2.22%;
  bottom: 48%;
}
  
}
@media (max-width: 768px) {
  .ev {
    .accordion {
      padding: 0;
      .accordion-item {
        .accordion-header {
          .accordion-button {
            font-size: 16px;
            padding: 15px;
          }
        }

        .accordion-collapse {
          .accordion-body {
            padding: 15px;
            font-size: 14px;
            .enventTitle {
              font-size: 18px;
              font-size: 400;
            }
            .subsection {
              .enventTitle:nth-child(1) {
                margin-top: 50px;
              }
              .image-carousel {
                padding: 10px 0;
                .image-container {
                  height: 180px;
                  @media (max-width: 410px) {
                    height: 120px;
                  }
                }
              }
              .sub-content {
                color: black;
                line-height: 19px;
                font-size: 15px;
                text-align: justify;
              }
            }
          }

          .related-products {
            margin: 0;
            padding: 0;
            .all-related-products {
              .trending-cards {
                height: 100%;
                width: 95%;
                margin: auto;
                @media (max-width: 1068px) {
                  width: 100%;
                }
              }
            }
            @media (max-width: 370px) {
              .heading-section {
                .heading {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }

      // Responsive Design
      @media (max-width: 768px) {
        .accordion-item {
          margin-bottom: 0.5rem;

          .accordion-header {
            .accordion-button {
              font-size: 14px;
              padding: 12px;
            }
          }
        }
      }
    }
  }
}

//
@media (max-width: 554px) {
.custom-next {
  cursor: pointer;
  position: absolute;
  right: -3%;
  bottom: 48%;
  font-size: 1.1rem;
}
.custom-prev {
  cursor: pointer;
  font-size: 1.1rem;

  position: absolute;
  left: -3.22%;
  bottom: 48%;
  z-index: 22;
}
  
}
