.foooter {
  background: var(--bg-tertiary);
  display: flex;
  flex-direction: column;
  padding: 7rem 5rem 3rem 5rem;

  .top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;

    .left-section {
      width: 35%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 100%;
      overflow: hidden;

      .footer-logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0rem;

        .logo-icon {
          height: 70px;
          width: auto;
        
          border-radius: 5px;
          object-fit: cover;
        }
        .name {
          width: 60%;

          h2 {
            color: var(--text-secondary);
            line-height: 2.3rem;

            span {
              color: var(--text-theme);
            }
          }
        }
      }

      .social-icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 1rem;

        .connect-text {
          color: var(--text-secondary);
        }

        .linkedin,
        .facebook,
        .twitter {
          color: var(--text-secondary);
        }

        .linkedin:hover {
          color: var(--linkedin);
        }

        .facebook:hover {
          color: var(--facebook);
        }

        .twitter:hover {
          color: var(--twitter);
        }
      }
    }

    .right-section {
      width: 60%;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      max-width: 100%;

      .tab-column-1,
      .tab-column-2,
      .tab-column-3 {
        h5,
        .tab-links p,
        .details p {
          color: var(--text-secondary);
        }

        h5 {
          margin-bottom: 0.8rem;
          line-height: 1.5rem;
        }

        .tab-links {
          display: flex;
          flex-direction: column;
          gap: 10px;

          p {
            font-weight: 300;
            cursor: pointer;
            width: fit-content;

            &:hover {
              color: var(--text-theme);
            }
          }
        }

        .details {
          display: flex;
          flex-direction: column;
          gap: 10px;

          p {
            font-weight: 300;
            width: fit-content;
          }
        }
      }

      .tab-column-1 {
        width: 20%;
      }
      .tab-column-2 {
        width: 30%;
      }
      .tab-column-3 {
        width: 40%;
      }
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
    flex-wrap: wrap;
    gap: 1rem;

    p {
      color: var(--text-secondary);

      span {
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 980px) {
  .foooter {
    padding: 4rem 4rem 3rem 4rem;

    .top-section {
      flex-direction: column;
      gap: 4rem;

      .left-section,
      .right-section {
        width: 100%;
      }

      .left-section {
        gap: 1rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .foooter {
    padding: 3rem;

    .top-section {
      gap: 2rem;

      .left-section {
        .footer-logo {
          h1 {
            font-size: 1.5rem;
          }
        }
      }

      .right-section {
        .tab-column-1,
        .tab-column-2,
        .tab-column-3 {
          h3 {
            font-size: 0.9rem;
            line-height: 1.1rem;
          }

          .tab-links p,
          .details p {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .foooter {
    padding: 2rem;

    .top-section {
      .left-section {
        gap: 1rem;
        .logo-icon {
          height: 50px !important;

        }
        .social-icons {
          p {
            width: 50%;
          }
        }
        .footer-logo {
          img {
            width: 70px;
          }
          .name {
            h2 {
              margin: 0;
              font-size: 1.5rem;
              line-height: 1.6rem;
            }
          }
        }
      }

      .right-section {
        flex-direction: column;
        gap: 1rem;

        .tab-column-1,
        .tab-column-2,
        .tab-column-3 {
          width: 100%;

          h3 {
            margin-bottom: 0.5rem;
          }

          .tab-links,
          .details {
            gap: 5px;
          }
        }
      }
    }

    .bottom-section {
      margin-top: 2rem;
    }
  }
}
