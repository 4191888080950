.product-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  .head {
    .inner-head-1 {
      .first-part-heading {
        text-align: center;
        font-size: 2rem;
        margin-bottom: 20px;
        background: linear-gradient(88.23deg, #097dc6 36.18%, #51aae2 115.5%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        font-weight: bold;
      }
    }
  }
  .para {
    color: #131313a6;
    line-height: normal;
  }
}

.product-title {
  text-align: start;
  font-size: 2rem;
  margin-bottom: 20px;
  background: linear-gradient(88.23deg, #097dc6 36.18%, #51aae2 115.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.product-card {
  display: flex;
  gap: 20px;
  // margin-bottom: 30px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.dialog-container {
  position: fixed;
  top: 0%;
  left: 0%;
  transition: transform(-50%, -50%);
  z-index: 9;
}

.product-image-container {
  flex: 1;
  max-width: 120px;
}

.product-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  // border: 1px solid red;
}

.product-details {
  flex: 2;
  padding-left: 20px;
  .btn-con {
    text-align: end;
    display: flex;
    justify-content: end;
    .alldata-contact-btn {
      @media (max-width: 500px) {
        padding: 3px 19px;
      }
    }
  }
}

.product-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.product-description {
  margin-bottom: 20px;
  line-height: 1.5;
  color: #555;
  
}

.product-features {
  list-style: disc;

  margin-left: 20px;
  color: #333;
}

@media (max-width: 800px) {
  .product-title {
    font-size: 1.7rem;
  }

  .product-card {
    gap: 10px;

    box-shadow: 0 0 5px 5px rgba(145, 145, 145, 0.137);
  }

  .product-image-container {
    flex: 1;
    // max-width: 300px;
  }

  .product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }

  .product-details {
    flex: 2;
    padding-left: 20px;
    h3 {
      font-size: 1.2rem;
    }
  }

  .product-name {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }

  .product-description {
    margin-bottom: 5px;
    line-height: 18px;
    font-size: 14px;
    color: #555;
  }

  .product-features {
    list-style: disc;
    margin-left: 20px;
    color: #333;
    li {
      line-height: 18px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 550px) {
  .product-title {
    font-size: 1.7rem;
  }

  .product-card {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .product-image-container {
    flex: 1;
    max-width: 100%;
    width: 100% !important;
    margin: auto;
    height: 60%;

    // @media (max-width: 420px) {
    //   max-width: 100%;
    //   height: auto;
    // }
  }

  .product-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    margin-bottom: 1.4rem;
  }

  .product-details {
    flex: 2;
    padding-left: 20px;

    h3 {
      font-size: 1.2rem;
    }
  }

  .product-name {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }

  .product-description {
    margin-bottom: 5px;
    line-height: 18px;
    font-size: 14px;
    color: #555;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Number of lines to show */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-features {
    list-style: disc;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    color: #333;
    li {
      line-height: 18px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
