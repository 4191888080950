.emulsifiers-page {
  font-family: Arial, sans-serif;
  max-width: 1400px;
  margin: 0 auto;

  .product-section {
    margin-bottom: 3rem;
    background: rgba(245, 245, 245, 0.8);
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

    .heading {
      background: linear-gradient(90deg, #097dc6, #51aae2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: clamp(1.8rem, 5vw, 2.5rem);
      font-weight: 700;
      margin-bottom: 1.5rem;
      text-align: center;
      line-height: 1.2;
    }

    > p {
      font-size: clamp(1rem, 2.5vw, 1.1rem);
      color: #34495e;
      line-height: 1.6;
      margin-bottom: 2rem;
      text-align: justify;
    }

    .main-container {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      background-image: url("../../../assets/images/Ellipse 21.png");
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 25px;
      padding: 2rem;
      margin: 2rem 0;

      .left-container,
      .right-container {
        flex: 1;
        min-width: 300px;
        padding: 1.5rem;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 25px;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }
      }
    }

    .subheading {
      background: linear-gradient(90deg, #097dc6, #51aae2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: clamp(1.5rem, 4vw, 2rem);
      font-weight: 700;
      margin: 1.5rem 0 1rem;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding: 0.75rem 0;
        font-size: clamp(0.9rem, 2.5vw, 1rem);
        color: #34495e;
        line-height: 1.5;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        &:last-child {
          border-bottom: none;
        }

        strong {
          color: #2c3e50;
          font-weight: 600;
        }
      }
    }
  }

  // Tablet and below
  @media screen and (max-width: 768px) {
    padding: 1rem;

    .product-section {
      padding: 1.5rem;
      margin-bottom: 2rem;

      .main-container {
        padding: 1.5rem;
        flex-direction: column;

        .left-container,
        .right-container {
          min-width: 100%;
        }
      }
    }
  }

  // Mobile
  @media screen and (max-width: 480px) {
    padding: 0.75rem;

    .product-section {
      padding: 1rem;

      .main-container {
        padding: 1rem;
        margin: 1.5rem 0;
      }

      ul li {
        padding: 0.5rem 0;
      }
    }
  }
}
