.containerss {
  width: 95%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-image: url("../../../assets/images/Ellipse 21.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;
  .title {
    color: white;
    font-weight: 700;
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  .section {
    background: #f9f9f9;
    padding: 1.5rem;
    margin-bottom: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;

    .title {
      color: #097dc6;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .description {
      font-size: 16px;
      line-height: 1.5;
    }

    .section-title {
      color: #51aae2;
      font-size: 22px;
      font-weight: 600;
      margin-top: 20px;
      border-bottom: 3px solid #097dc6;
      display: inline-block;
      padding-bottom: 5px;
    }

    .products {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 10px 0;
      .product-card {
        width: 49%;
        display: flex;
        flex-direction: column;
        gap: 0;
        background: white;
        border-left: 5px solid #097dc6;
        padding: 15px;
        border-radius: 8px;
        margin-top: 10px;
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: scale(1.01);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
        }

        .product-title {
          font-size: 1.8rem;
          font-weight: 700;
          background: linear-gradient(90deg, #097dc6, #51aae2);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: 0;
          padding: 0;
        }

        .product-description {
          font-size: 14px;
          padding-bottom: 0;
          color: #131313a6;
        }

        .product-list {
          padding-left: 20px;
          font-size: 14px;

          li {
            //   margin-bottom: 5px;
            list-style: decimal;
            margin: 0;
            padding: 0;
            color: #131313a6;
          }
        }
      }
    }

    .benefits-list {
      padding-left: 20px;
      font-size: 16px;
      margin-top: 15px;
      li {
        margin-bottom: 8px;
        font-weight: 500;
        color: #097dc6;
        list-style: decimal;
      }
    }
  }
}

@media (max-width: 768px) {
  .containerss {
    .title {
      font-size: 1.4rem;
    }
    .section {
      padding: 1rem;

      .products {
        flex-direction: column;
        margin: 10px 0;
        .product-card {
          width: 100%;

          .product-title {
            font-size: 1.2rem;
            font-weight: 700;
            background: linear-gradient(90deg, #097dc6, #51aae2);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0;
            padding: 0;
          }

          .product-description {
            font-size: 14px;
            padding-bottom: 0;
            color: #131313a6;
          }

          .product-list {
            padding-left: 20px;
            font-size: 14px;

            li {
              //   margin-bottom: 5px;
              list-style: decimal;
              margin: 0;
              padding: 0;
              color: #131313a6;
            }
          }
        }
      }

      .benefits-list {
        padding-left: 20px;
        font-size: 16px;

        li {
          margin-bottom: 8px;
          font-weight: 500;
          color: #097dc6;

          //   &::before {
          //     content: "✔ ";
          //     color: #51aae2;
          //     font-weight: bold;
          //   }
        }
      }
    }
  }
}
