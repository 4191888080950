.updates {
  background: var(--bg-primary);
  padding: 3rem 7rem;

  .heading-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .heading {
      color: var(--text-grey);
      font-weight: 400;
      text-transform: uppercase;
    }

    .subheading-section {
      margin-top: 1rem;
      width: 100%;

      .subheading {
        font-size: 3.3rem;
        line-height: 3.5rem;
        color: var(--text-primary);
        font-weight: 700;

        .span-text {
          -webkit-background-clip: text;
          color: transparent;
        }
      }
    }

    .view-all-section {
      background: var(--bg-secondary);
      padding: 10px 2rem;
      height: fit-content;
      border-radius: 30px;
      display: flex;
      align-self: flex-end;

      .view-all {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        p,
        .arrow {
          color: var(--text-secondary);
        }
      }
    }
  }

  .blogs-container {
    margin: 3rem 0;

    .section-heading {
      color: var(--text-medium-grey);
      font-weight: 400;
    }

    .main-blog-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;

      .left-blogs-container {
        width: 60%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;

        .left-single-blog {
          cursor: pointer;

          .left-blog-image-container {
            height: 300px;
            width: 100%;

            .left-blog-image {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 20px;
              border: 0.8px solid var(--light-shadow);
            }
          }

          .left-blog-details-container {
            .left-blog-title-container {
              margin: 1rem 0;

              .left-blog-title {
                line-height: 2rem;
                color: var(--text-primary);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .left-blog-description-container {
                .left-blog-description {
                  color: var(--text-grey);
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .separator-line {
                border-bottom: 1px solid var(--light-shadow);
              }

              .bottom-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .date-container,
                .views-container {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 7px;

                  .date,
                  .views {
                    color: var(--text-grey);
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }

      .right-blogs-container {
        width: 30%;

        .right-single-blog {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid var(--light-shadow);

          .right-blog-right-container {
            .right-blog-image-container {
              height: 120px;
              width: 120px;
              border-radius: 10px;

              .right-blog-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 10px;
                border: 0.8px solid var(--light-shadow);
              }
            }
          }

          .right-blog-details-container {
            .right-blog-title-container {
              margin-bottom: 10px;

              .right-blog-title {
                // line-height: 2rem;
                color: var(--text-primary);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .date-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 7px;

              .date {
                color: var(--text-grey);
                font-size: 0.9rem;
              }
            }
          }
        }

        .right-single-blog:not(:last-child) {
          margin-bottom: 1rem;
        }

        .right-single-blog:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  .events-container {
    margin: 3rem 0;

    .section-heading {
      color: var(--text-medium-grey);
      font-weight: 400;
    }

    .all-events-container-grid {
      cursor: pointer;
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      gap: 20px;
      align-items: center;

      .image1,
      .image2 {
        height: 270px;
        width: 450px;
      }
      .image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        
        p {
          display: none;
          padding: 1rem;
        }
        &:hover::after {
          content: "";
          height: 100%;
          width: 100%;
          position: absolute;
          background: #000000c4;
         
        }
      }
      .image:hover {
        p {
          position: absolute;
          z-index: 4;
          font-size: 12px;
          display: block;
          color: white;
        }
      
      }

      .image3 {
        width: 100%;
        height: 100%;
        max-height: 560px;
        grid-row: 1 / 3;
        grid-column: 2 / 3; /* Centered image in the second column */
        justify-self: center;

        img {
          object-fit: cover;
        }
      }

      .image4 {
        height: 100%;
        grid-row: 1 / 2;
        grid-column: 3;
      }

      .explore-more {
        height: 100%;
        width: 100%;
        background: linear-gradient(
            to bottom right,
            rgba(246, 246, 246, 0.3),
            rgba(246, 246, 246, 0)
          ),
          #131313;
        grid-column: 3;
        justify-self: start;
        border-radius: 10px;
        display: flex;
        padding: 10px;

        .explore-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .explore {
            width: 60%;
            color: var(--text-secondary);
            font-size: 2rem;
            line-height: 2rem;
            text-align: end;
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }

      .image img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 10px;
      }

      .explore-more a {
        display: inline-block;
        text-decoration: none;
        color: var(--link-color);
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1350px) {
  .updates {
    .events-container {
      .all-events-container-grid {
        .image1,
        .image2 {
          height: 230px;
          width: 350px;
        }
      }
    }
  }
}

@media (max-width: 1250px) {
  .updates {
    padding: 3rem;

    .blogs-container {
      .main-blog-container {
        .left-blogs-container {
          gap: 2rem;
        }

        .right-blogs-container {
          width: 35%;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .updates {
    .events-container {
      .all-events-container-grid {
        .image1,
        .image2 {
          height: 280px;
          width: 450px;
          grid-column: 1 / 3;
          // grid-row: 2 / 3;
        }

        .image3 {
          height: 500px;
          grid-column: 1 / 4;
          img {
            object-position: top;
          }
        }

        .image4 {
          height: 280px;
          width: 450px;
          grid-row: 3;
          grid-column: 3 / 4;
        }

        .explore-more {
          height: 180px;
          width: 280px;
          grid-row: 4;
          grid-column: 3 / 4;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .updates {
    padding: 2rem;

    .blogs-container {
      .main-blog-container {
        .left-blogs-container {
          gap: 1rem;

          .left-single-blog {
            .left-blog-image-container {
              height: 220px;
            }

            .left-blog-details-container {
              .left-blog-title-container {
                margin: 10px 0;

                .left-blog-title {
                  line-height: 1.5rem;
                }
              }

              .description-container {
                .left-blog-description-container {
                  .left-blog-description {
                    line-height: 1.4rem;
                  }
                }

                .bottom-container {
                  .date-container,
                  .views-container {
                    .date,
                    .views {
                      color: var(--text-grey);
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }
          }
        }

        .right-blogs-container {
          .right-single-blog {
            padding-bottom: 10px;

            .right-blog-right-container {
              .right-blog-image-container {
                height: 100px;
                width: 100px;
              }
            }
          }

          .right-single-blog:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 970px) {
  .updates {
    .events-container {
      .all-events-container-grid {
        gap: 10px;

        .image1,
        .image2 {
          height: 280px;
          width: 380px;
          grid-column: 1 / 3;
          object-fit: contain;
        }

        .image3 {
          height: 430px;
          grid-column: 1 / 4;
          object-fit: contain;
        }

        .image4 {
          height: 280px;
          width: 380px;
          grid-row: 3;
          grid-column: 3 / 4;
          object-fit: contain;
        }

        .explore-more {
          height: 180px;
          width: 280px;
          grid-row: 4;
          grid-column: 3 / 4;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .updates {
    .events-container {
      .all-events-container-grid {
        gap: 10px;

        .image1,
        .image2 {
          height: 250px;
          width: 350px;
          grid-column: 1 / 3;
          object-fit: contain;
        }

        .image3 {
          height: 430px;
          grid-column: 1 / 4;
          object-fit: contain;
        }

        .image4 {
          height: 250px;
          width: 350px;
          grid-row: 3;
          grid-column: 3 / 4;
          object-fit: contain;
        }

        .explore-more {
          height: 180px;
          width: 280px;
          grid-row: 4;
          grid-column: 3 / 4;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .updates {
    .heading-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1.5rem;

      .subheading-section {
        margin-top: 10px;

        .subheading {
          font-size: 3rem;
          line-height: 3.3rem;
        }
      }

      .view-all-section {
        align-self: flex-start;
        padding: 8px 1rem;

        .view-all {
          p,
          .arrow {
            font-size: 0.9rem;
          }
        }
      }
    }

    .blogs-container {
      .main-blog-container {
        margin-top: 1rem;
        flex-direction: column;
        gap: 2rem;

        .left-blogs-container {
          width: 100%;
          gap: 2rem;

          .left-single-blog {
            .left-blog-image-container {
              height: 250px;
            }
          }
        }

        .right-blogs-container {
          width: 100%;

          .right-single-blog {
            .right-blog-right-container {
              .right-blog-image-container {
                height: 120px;
                width: 120px;
              }
            }

            .right-blog-details-container {
              .date-container {
                .date {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }

    .events-container {
      .all-events-container-grid {
        margin-top: 1rem;
        gap: 10px;

        .image1,
        .image2 {
          height: 400px;
          width: 100%;
          grid-column: 1 / 4;
        }

        .image3 {
          height: 400px;
          grid-column: 1 / 4;
        }

        .image4 {
          height: 400px;
          width: 100%;
          grid-row: 3;
          grid-column: 1 / 4;
        }

        .explore-more {
          height: 180px;
          width: 280px;
          grid-row: 6;
          grid-column: 1;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .updates {
    padding: 1.5rem;

    .heading-container {
      gap: 1rem;

      .subheading-section {
        .subheading {
          font-size: 2.3rem;
          line-height: 2.8rem;
        }
      }
    }

    .blogs-container {
      margin: 2rem 0;

      .main-blog-container {
        .left-blogs-container {
          gap: 1rem;

          .left-single-blog {
            .left-blog-image-container {
              height: 180px;
            }

            .left-blog-details-container {
              .left-blog-title-container {
                margin: 10px 0;

                .left-blog-title {
                  font-size: 1.3rem;
                  line-height: 1.5rem;
                }
              }

              .description-container {
                gap: 10px;

                .left-blog-description-container {
                  .left-blog-description {
                    font-size: 0.9rem;
                    line-height: 1.2rem;
                  }
                }

                .bottom-container {
                  flex-wrap: wrap;
                }
              }
            }
          }
        }

        .right-blogs-container {
          .right-single-blog {
            .right-blog-right-container {
              .right-blog-image-container {
                height: 100px;
                width: 100px;
              }
            }

            .right-blog-details-container {
              .date-container {
                .date {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }

    .events-container {
      .all-events-container-grid {
        .image1,
        .image2,
        .image3,
        .image4 {
          height: 250px;
        }

        .explore-more {
          height: 100px;
          width: 230px;
          grid-row: 6;
          grid-column: 1;

          .explore-link {
            .explore {
              font-size: 1.5rem;
              line-height: 1.7rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .updates {
    padding: 1rem;

    .heading-container {
      .heading {
        font-size: 1.3rem;
      }

      .subheading-section {
        .subheading {
          font-size: 2rem;
          line-height: 2.2rem;
        }
      }
    }

    .blogs-container {
      margin: 1.5rem 0;

      .section-heading {
        font-size: 1.2rem;
      }

      .main-blog-container {
        margin-top: 10px;
        gap: 1rem;

        .left-blogs-container {
          gap: 1rem;
          flex-direction: column;

          .left-single-blog {
            .left-blog-image-container {
              height: 200px;
            }
          }
        }

        .right-blogs-container {
          .right-single-blog {
            gap: 10px;

            .right-blog-right-container {
              .right-blog-image-container {
                height: 90px;
                width: 90px;
              }
            }

            .right-blog-details-container {
              .right-blog-title-container {
                .right-blog-title {
                  font-size: 1rem;
                }
              }

              .date-container {
                .date {
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }
    }

    .events-container {
      .section-heading {
        font-size: 1.2rem;
      }

      .all-events-container-grid {
        margin-top: 10px;

        .image1,
        .image2,
        .image3,
        .image4 {
          height: 200px;
        }
        .image3 {
          height: 250px;
          img {
            object-position: top !important;
          }
        }

        .explore-more {
          height: 80px;
          width: 200px;
          grid-row: 6;
          grid-column: 1;

          .explore-link {
            .explore {
              font-size: 1.3rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }
}
