.ph-container {
  margin: 0 auto;
  padding: 3rem;
  font-family: "Segoe UI", sans-serif;
  color: #333;

  .title {
    font-size: 34px;
    font-weight: 700;
    color: #0c7a52;
    margin-bottom: 8px;
    font-size: 1.8rem;
    font-weight: 700;
    background: linear-gradient(90deg, #097dc6, #51aae2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }

  .subtitle {
    font-size: 18px;
    font-style: italic;
    color: #666;
    margin-bottom: 30px;
  }
  section {
    h2 {
      font-size: 2.2rem;
      font-weight: 700;
      background: linear-gradient(90deg, #097dc6, #51aae2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: fit-content;
    }
  }
  .mai-parent {
    display: flex;
    justify-content: space-between;

    section {
      width: 49%;
      margin-top: 1rem;
      padding: 2rem 1.5rem;
      background-image: url("../../assets/images/Ellipse\ 21.png");
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 25px;
      position: relative;
      z-index: 2;

      &::before {
        content: "";
        height: 96%;
        width: 96%;
        background: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
        z-index: -1;
      }

      h2 {
        font-size: 22px;
        font-weight: 600;
        color: #222;
        margin-bottom: 12px;

        padding-left: 10px;
        font-size: 1.8rem;
        font-weight: 700;
        background: linear-gradient(90deg, #097dc6, #51aae2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        width: fit-content;
      }

      p {
        font-size: 16px;
        line-height: 1.7;
        color: #444;
      }

      ul {
        list-style: decimal;
        margin-left: 25px;
        color: #444;
        line-height: 1.6;
        margin: 0;

        .sub-list {
          list-style: circle;
          margin-left: 20px;
          margin-top: 6px;
        }

        li {
          margin-bottom: 8px;
        }

        strong {
          color: #0c7a52;
        }
      }
    }
  }
  .img-sec {
    padding: 2rem 1.5rem;
    background-image: url("../../assets/images/Ellipse\ 21.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
    .img-c {
      background: white;
      border-radius: 10px;
      padding: 10px;

      img {
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: "800px") {
  .ph-container {
    margin: 0 auto;
    padding: 1.5rem;
    font-family: "Segoe UI", sans-serif;
    color: #333;

    .title {
      font-weight: 500;

      font-size: 1.5rem;
      margin-bottom: 0;
    }

    .subtitle {
      font-size: 15px;
    }
    section {
      h2 {
        font-weight: 500;

        font-size: 1.5rem;
        margin-bottom: 0;
      }
    }
    .mai-parent {
      flex-direction: column;

      section {
        padding: .9rem;
        width: 100%;

        h2 {
          font-weight: 500;

          font-size: 1.5rem;
          margin-bottom: 0;
        }

        p {
          line-height: normal;
        }

        ul {
          li {
            margin-bottom: 5px;
          }
        }
      }
    }
    .img-sec {
      padding: 1rem;
      gap: 5px;

      .img-c {
        img {
          border-radius: 10px;
        }
        p {
          font-size: 12px;
          line-height: 13px;
          br {
            display: none;
          }
        }
      }
    }
  }
}
