.privacy-container {
  padding: 2rem;
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;

  h1 {
    font-size: 2rem;
    color: #0056b3;
    margin-bottom: 1rem;
    border-bottom: 2px solid #0056b3;
    padding-bottom: 0.5rem;
  }

  p {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #555;
  }

  ol {
    margin-left: 1.5rem;
    li {
      margin-bottom: 1rem;
      font-size: 1rem;

      strong {
        color: #0056b3;
      }
    }
  }
}
