.mainagroProduct {
  .product-section1 {
    width: 100%;

    height: 27rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 20%;
    background-color: rgb(134, 134, 134);
    background-blend-mode: none;
    box-shadow: 0px -5px 24px 0px #00000040 inset;
    position: relative;

    .imgSection {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center; /* Centers horizontally */
      align-items: center;
      z-index: 2;

      .left-section {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        text-align: center;
        flex-direction: column;

        p,
        h1,
        .jump-btn-container p {
          color: var(--text-secondary);
        }

        p {
          font-weight: 300;
          font-size: 1rem;
        }

        h1 {
          width: 65%;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 3.1rem;
          line-height: 3.3rem;
          margin-top: 1rem;
          margin-bottom: 3rem;
        }

        .jump-btn-container {
          cursor: pointer;
          width: fit-content;
          border-radius: 10px;
          border: 1px solid white;
          padding: 10px 3rem;
          background: linear-gradient(to right, #0085d9, #004773d4);
          box-shadow: 3px 3px 10px var(--text-primary-light);

          p {
            text-align: center;
          }
        }
      }

      // .right-section {
      //   width: 40%;
      //   position: relative;
      //   display: flex;
      //   align-items: center;

      //   img {
      //     position: absolute;
      //     top: 0;
      //     right: 0;
      //   }

      //   .leaf {
      //     z-index: 4;
      //   }

      //   .line {
      //     right: 3%;
      //   }

      //   .line,
      //   .whitebg {
      //     z-index: 3;
      //   }

      //   .left-btn-section {
      //     position: absolute;
      //     z-index: 10;
      //     left: -8.5%;
      //     display: flex;
      //     flex-direction: column;
      //     align-items: end;
      //     gap: 1.5rem;

      //     .btn-container {
      //       cursor: pointer;
      //       background: var(--bg-light);
      //       display: flex;
      //       align-items: center;
      //       width: fit-content;
      //       gap: 10px;
      //       padding: 5px 1.2rem;
      //       border-radius: 5px;
      //       box-shadow: 2px 2px 5px var(--text-primary-light);

      //       p {
      //         margin: 0;
      //         font-size: 0.9rem;
      //         font-weight: 500;
      //       }
      //     }
      //   }
      // }
    }

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(to right, #086aa8ea, #51aae26c);
      z-index: 1;
    }
  }

  .agro-container {
    .agrohead {
      font-weight: 700;
      font-size: 2.2rem;
      color: #51aae2;
      margin: 0;
      text-transform: uppercase;

      span {
        color: #131313;
      }
    }
    .inner-head-2 {
      margin-top: 10px;
      max-width: 60%;
      text-align: center;
      margin: auto;

      p {
        color: var(--text-medium-grey);
        font-size: 0.9rem;
        line-height: 1.1rem;
      }
    }
  }
}

@media (max-width: 1340px) {
  .mainagroProduct {
    .product-section1 {
      .imgSection {
        .left-section {
          h1 {
            width: 90%;
          }
        }

        .right-section {
          width: 45%;

          img {
            height: 100%;
            object-fit: cover;
          }

          .left-btn-section {
            left: -13%;
          }
        }
      }
    }
  }
}

@media (max-width: 1170px) {
  .mainagroProduct {
    .product-section1 {
      height: 23rem;

      .imgSection {
        .left-section {
          width: 45%;
          padding-left: 3rem;

          h1 {
            width: 100%;
          }
        }

        .right-section {
          width: 50%;

          .left-btn-section {
            left: -7%;
          }
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .mainagroProduct {
    .product-section1 {
      height: 20rem;

      .imgSection {
        .left-section {
          width: 40%;
          padding-left: 2rem;

          h1 {
            font-size: 2.3rem;
            line-height: 2.6rem;
          }
        }

        .right-section {
          width: 55%;

          .left-btn-section {
            left: -5%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .mainagroProduct {
    .product-section1 {
      .imgSection {
        padding: 0 2rem;

        .left-section {
          width: 55%;
          padding-left: 0;
        }

        .right-section {
          width: 40%;

          img {
            display: none;
          }

          .left-btn-section {
            position: static;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .mainagroProduct {
    .product-section1 {
      height: 16rem;

      .imgSection {
        padding: 0 1.2rem;

        .left-section {
          width: 55%;

          h1 {
            font-size: 2rem;
            line-height: 2.3rem;
            margin-bottom: 2rem;
          }
        }

        .right-section {
          width: fit-content;

          .left-btn-section {
            gap: 1rem;
          }
        }
      }
    }
    .agro-container {
      .agro-container {
        font-size: 1.5rem;
      }
    }
  }
}

@media (max-width: 460px) {
  .mainagroProduct {
    .product-section1 {
      height: 15rem;

      .imgSection {
        padding: 0 1rem;

        .left-section {
          width: 60%;

          h1 {
            font-size: 1.8rem;
            line-height: 2rem;
            margin-bottom: 2rem;
          }

          p {
            font-size: 0.9rem;
          }

          .jump-btn-container {
            padding: 10px 2rem;
          }
        }

        .right-section {
          .left-btn-section {
            gap: 12px;

            .btn-container {
              p {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .mainagroProduct {
    .product-section1 {
      height: fit-content;
      padding: 1.3rem 0;

      .imgSection {
        .left-section {
          width: 60%;

          h1 {
            font-size: 1.3rem;
            line-height: 1.5rem;
            margin-bottom: 1rem;
          }

          p {
            font-size: 0.8rem;
          }

          .jump-btn-container {
            width: 90%;
            padding: 10px 1rem;
          }
        }

        .right-section {
          .left-btn-section {
            .btn-container {
              gap: 5px;

              p {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }
  }
}
