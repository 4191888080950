.resourcecenters {
  .resource-hero-img-section {
    background-image: url("../../assets/images/event image/2024/2-3 set/sat\ \(4\).webp");
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: fit-content;
    // background-size: cover;
    background-repeat: no-repeat;
    background-position: 100%;
    // margin-top: -30px;
    .img-overlay {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: -1;
      background: #4384d9b4;
      .resource-main-hero-content-section {
        padding: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: rgb(255, 255, 255);
        .resource-content {
          font-size: 4rem;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .resourcecenters {
      .resource-hero-img-section {
        margin-bottom: 1rem;
        height: 200px !important;
      .img-overlay {
        object-fit: contain;
        .resource-main-hero-content-section {
          .resource-content {
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
          }
        }
      }
    }
  }
}
