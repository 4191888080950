.top-banner {
  width: 100%;
  position: relative;

  // .left-container {
  //   .announcemet {
  //     cursor: pointer;
  //     width: fit-content;
  //     background: var(--bg-tertiary);
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     gap: 7px;
  //     padding: 8px 18px;
  //     border-radius: 8px;

  //     p {
  //       color: var(--text-secondary);
  //       font-size: 0.8rem;
  //     }

  //     .confetti {
  //       height: 20px;
  //       width: 20px;
  //       object-fit: cover;
  //     }
  //   }

  //   .header {
  //     margin-top: 2rem;
  //     margin-bottom: 2.5rem;
  //     max-width: 600px;

  //     h1 {
  //       font-size: 3rem;
  //       line-height: 3.8rem;
  //       -webkit-background-clip: text;
  //       color: transparent;

  //       .span-text {
  //         -webkit-background-clip: text;
  //         color: transparent;
  //       }
  //     }
  //   }

  //   .about {
  //     max-width: 500px;
  //     margin-bottom: 2rem;

  //     p {
  //       color: var(--text-grey);
  //     }
  //   }

  //   .btn-container {
  //     display: flex;
  //     flex-direction: row;
  //     gap: 20px;
  //     align-items: center;
  //     margin-bottom: 3rem;
  //   }

  //   .connect {
  //     display: flex;
  //     flex-direction: row;
  //     gap: 15px;
  //     align-items: center;

  //     .linkedin:hover {
  //       color: var(--linkedin);
  //     }

  //     .facebook:hover {
  //       color: var(--facebook);
  //     }

  //     .twitter:hover {
  //       color: var(--twitter);
  //     }
  //   }
  // }

  // .right-container {
  //   display: flex;
  //   align-items: center;
  //   z-index: 1;

  //   .hero-image-container {
  //     height: auto;
  //     width: 530px;

  //     .hero-image {
  //       height: 100%;
  //       width: 100%;
  //       object-fit: cover;
  //     }
  //   }
  // }

  // .rectangle-container {
  //   margin-top: 2rem;
  //   position: absolute;
  //   right: 0%;
  //   top: -1.5%;
  //   z-index: 0;
  //   height: 580px;
  //   width: 350px;

  //   .overlay {
  //     position: absolute;
  //     background: rgba(0, 134, 217, 0.7);
  //     z-index: 1;
  //     height: 100%;
  //     width: 100%;
  //     border-top-left-radius: 30px;
  //     border-bottom-left-radius: 30px;
  //   }

  //   .rectangle {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //     border-top-left-radius: 30px;
  //     border-bottom-left-radius: 30px;
  //   }
  // }

  .item {
    height: 600px;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit:fill;
    }
  }

  .slick-prev {
    left: 2%;
    display: none;
  }

  .slick-next {
    right: 2%;
  }

  .slick-next::before,
  .slick-prev::before {
    font-size: 1.7rem;
  }
}

@media (max-width: 768px) {
  .top-banner {
    .item {
      height: 400px;
    }

    .slick-next::before,
    .slick-prev::before {
      font-size: 1.3rem;
    }
  }
}

@media (max-width: 550px) {
  .top-banner {
    .item {
      height: 300px;
    }
  }
}

@media (max-width: 470px) {
  .top-banner {
    .item {
      height: 200px;
    }
  }
}
