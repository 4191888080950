.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  text-align: justify;
  .first-part-heading {
    background: linear-gradient(88.23deg, #097dc6 36.18%, #51aae2 115.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-weight: 700;
  }
}

.checkourforall {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1.5rem;
  gap: 20px;

  .allproduct {
    // height: 23rem;
    width: 31%;
    padding: 2rem .5rem 2rem 1.5rem;

    cursor: pointer;
    text-align: center;
    // background: linear-gradient(88.23deg, #097dc6 36.18%, #51aae2 115.5%);
   
    & :hover {
      transform: translateY(-1px);
      transition: transform 0.5s ease;
    }
    .chem-head {
      background: linear-gradient(88.23deg, #097dc6 36.18%, #51aae2 115.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      font-weight: 700;
     }
    .product-image {
      width: 100%;
      height: 70%;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        // position: absolute;
        object-fit: contain;
      }
    }
    .btn {
      padding: 5px 19px;
      font-size: 14px;
      background: #468cd1;
      // background: #000;
    }
    .dialog-container {
      position: fixed;
      top: 0%;
      left: 0%;
      transition: transform(-50%, -50%);
      z-index: 9;
    }
  }
}

@media (max-width: 768px) {
  .checkourforall {
    justify-content: center;

    gap: 5px;
    .allproduct {
      width: 48%;
      // height: 19rem;
      .btn {
        padding: 2px 14px;
      }
      h5 {
        font-size: 1rem;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Limits text to two lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@media (max-width: 450px) {
  .checkourforall {
    justify-content: space-around;
    gap: 0;
    row-gap: 10px;

    .allproduct {
      width: 49%;

      .btn {
        padding: 2px 14px;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 390px) {
  .container {
    width: 98% !important;
    margin: auto;
  }
}
