.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  .logo-icon {
    height: 50px;
    // width: 50px;
    // mix-blend-mode:lighten;
    object-fit: contain;
    border-radius: 5px;
    @media (max-width:400px) {
      height: 40px;
      
    }
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      margin: 0;
      font-size: 1rem;
      line-height: 1.1rem;
      color: var(--text-secondary);
    }
  }
}
