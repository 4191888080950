.resourcecenter {
  .resource-hero-img-section {
    background-image: url("../../assets/images/Categories/plantimg.png");
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: fit-content;
 
    background-repeat: no-repeat;
    background-position: 100%;
    // margin-top: -30px;
    .img-overlay {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      z-index: -1;
      background: #4384d9b4;
      .resource-main-hero-content-section {
        padding: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: rgb(255, 255, 255);
        .resource-content {
          font-size: 4rem;
          font-weight: 700;
          text-align: center;
        }
      }
    }
  }
  .search {
    z-index: 99;
    margin-bottom: 1rem;
    margin-top: -5rem;
    .search-container {
      width: 85%;
      margin: auto;
      background-color: #f5f5f5;
      border-radius: 15px;
      padding: 30px 30px;
      // box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
      margin-top: 20px;
      .forminput {
        display: flex;
        justify-content: space-between;
        background-color: white;
        align-items: center;
        width: 70%;
        padding: 0 1rem;
        margin-left: auto;
        border-radius: 10px;
        color: #c6a3a3;
        border: 1px solid rgba(128, 128, 128, 0.322);
        input {
          padding: 10px;
          width: 90%;
          outline: none;
          border: none;
          border-radius: 10px;
          color: #000000;
        }
        .icons {
          color: black;
          height: 20px;
          width: 20px;
        }
      }

      .posts {
        width: 100%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        gap: 40px;
        .left-single-blog {
          cursor: pointer;
          width: 220px;
          .left-blog-image-container {
            height: 220px;
            width: 220px;

            .left-blog-image {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 20px;
              border: 0.8px solid var(--light-shadow);
            }
          }

          .left-blog-details-container {
            .left-blog-title-container {
              margin: 1rem 0;

              .left-blog-title {
                line-height: 2rem;
                color: var(--text-primary);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .left-blog-description-container {
                .left-blog-description {
                  color: var(--text-grey);
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .separator-line {
                border-bottom: 1px solid var(--light-shadow);
              }

              .bottom-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .date-container,
                .views-container {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 7px;

                  .date,
                  .views {
                    color: var(--text-grey);
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    .search-results {
    
      
      width: 90%;
      margin: auto;
      padding: 0px;
      .no-results {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          padding-top: 30px;
          font-size: 22px;
        }
      }
      .blog-result {
        .column-gapping {
          padding: 20px;
          border-radius: 20px;
          cursor: pointer;
          .blog-card {
            // box-shadow: 0px 0px 20px 0px rgb(107, 143, 178, 0.25);
            border-radius: 20px;
            padding: 5px;
            background-color: white;
            .img-outer {
              align-items: center;
              justify-content: center;
              display: flex;
              border-radius: 20px 20px 10px 10px;
              height: 100%;
              width: 100%;
              overflow: hidden;
              img {
                height: 100%;
              }
            }
            .info {
              padding: 20px 15px 10px 10px;
              h5 {
                color: #666666;
              }
              .readmore {
                width: 150px;
                padding: 0px;
                border-radius: 10px;
                background-color: #666666;
                a {
                  text-align: center;
                  padding: 15px;
                  width: 100%;
                  display: block;
                  color: white;
                  text-decoration: none;
                  line-height: 1;
                  margin: 0;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
    .submit {
      display: flex;
      height: 100%;
      align-items: center;
      button {
        border: none;
        border-radius: 10px;
        padding: 10px 20px;
        color: white;
        background-image: linear-gradient(to right, #097dc6, #51aae2);

        // background-image: linear-gradient(30deg, #0f2699, #00aaff);
      }
    }
  }
  .latest-posts {
    .container-main {
      padding: 20px 30px;
      // box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
      background-color: #f5f5f5;
      width: 85%;
      margin: auto;
      border-radius: 15px;

      .heading {
        position: relative;
        padding: 10px;
        h5 {
          width: fit-content;
          margin: auto;
          position: relative;
          font-size: 45px;
          -webkit-text-fill-color: #0000;
          -webkit-background-clip: text;
          background-clip: text;

          background-image: linear-gradient(to right, #097dc6, #51aae2);

          &:before {
            content: "";
            position: absolute;
            width: 130px;
            height: 5px;
            background: linear-gradient(to right, #097dc6, #51aae2);

            top: 100%;
            border-radius: 20px;
          }
        }
      }
      .posts {
        width: 100%;
        margin: auto;
        padding: 20px;
        display: flex;

        .left-single-blog {
          cursor: pointer;

          width: 500px;
          .left-blog-image-container {
            height: 300px;
            width: 100%;

            .left-blog-image {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 20px;
              border: 0.8px solid var(--light-shadow);
            }
          }

          .left-blog-details-container {
            .left-blog-title-container {
              margin: 1rem 0;

              .left-blog-title {
                line-height: 2rem;
                color: var(--text-primary);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .left-blog-description-container {
                .left-blog-description {
                  color: var(--text-grey);
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .separator-line {
                border-bottom: 1px solid var(--light-shadow);
              }

              .bottom-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .date-container,
                .views-container {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 7px;

                  .date,
                  .views {
                    color: var(--text-grey);
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .all-posts {
    width: 85%;
    margin: auto;
    margin: 35px auto;
    padding: 20px 30px;
    // box-shadow: 0px 0px 30px 2px rgba(128, 128, 128, 0.496);
    background-color: #f5f5f5;
    border-radius: 15px;

    .container-main {
      width: 100%;

      margin: auto;
      .heading {
        position: relative;
        h5 {
          width: fit-content;
          margin: auto;
          position: relative;
          font-size: 45px;
          margin-bottom: 5px;
          -webkit-text-fill-color: #0000;
          -webkit-background-clip: text;
          background-clip: text;
          background-image: -webkit-linear-gradient(
            45deg,
            #0f2699 19.79%,
            #00aaff 84.88%
          );

          &:before {
            content: "";
            position: absolute;
            width: 60px;
            height: 5px;
            background: linear-gradient(to right, #097dc6, #51aae2);

            top: 100%;
            border-radius: 20px;
          }
        }
      }
      .posts {
        width: 90%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px;
        gap: 20px;

        .left-single-blog {
          cursor: pointer;
          width: 280px;

          .left-blog-image-container {
            height: 270px;
            width: 270px;

            .left-blog-image {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 20px;
              border: 0.8px solid var(--light-shadow);
            }
          }

          .left-blog-details-container {
            .left-blog-title-container {
              margin: 1rem 0;

              .left-blog-title {
                line-height: 2rem;
                color: var(--text-primary);
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .description-container {
              display: flex;
              flex-direction: column;
              gap: 1rem;

              .left-blog-description-container {
                .left-blog-description {
                  color: var(--text-grey);
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .separator-line {
                border-bottom: 1px solid var(--light-shadow);
              }

              .bottom-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .date-container,
                .views-container {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 7px;

                  .date,
                  .views {
                    color: var(--text-grey);
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  //
  .gallary-section {
    padding: 2rem 1rem;

    gap: 10px;
    .gh {
      background: linear-gradient(
        250.78deg,
        rgb(85, 179, 238) 2.75%,
        rgb(6, 132, 146) 100.17%
      );
      -webkit-background-clip: text;
      color: transparent;
      width: fit-content;
      font-weight: bold;
      margin: auto;
      padding: 2rem;
    }
    .gallery-images {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 2rem;
      padding: 0;
      gap: 3px;
      .imgimg {
        width: 22%;
        height: 250px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .resourcecenter {
    .gallary-section {
      .gallery-images {
        .imgimg {
          width: 22%;
          height: 180px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .resourcecenter {
    .resource-hero-img-section {
      .resource-main-hero-content-section {
        .resource-content {
          padding-bottom: 3rem;
        }
      }
    }
    .search {
      padding: 0;
      .search-container {
        padding: 15px 0px;
        .search-text {
          text-align: center;
          h3 {
            font-size: 1.2rem;
          }
        }
        .forminput {
          margin: auto;
          input {
            font-size: 12px;
          }
        }
      }
      .search-results {
        padding: 0;
      }
    }
    .latest-posts {
      .container-main {
        padding: 20px 10px;
        width: 100%;
        .heading {
          h5 {
            padding: 0;
            font-size: 30px;
          }
        }
        .posts {
          padding: 20px 5px;
          width: 100%;
          .column-gapping {
            .blog-card {
              .img-outer {
                height: 200px;
                img {
                  border-radius: 15px;
                }
              }
              .info {
                h5 {
                  font-size: 18px;
                }
                p {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
    .all-posts {
      .container-main {
        padding: 10px 30px;
        width: 100%;
        .heading {
          h5 {
            padding: 0;
            font-size: 30px;
          }
        }
        .posts {
          width: 100%;
          .left-single-blog {
            cursor: pointer;
            width: 320px;

            .left-blog-image-container {
              height: 300px;
              width: 300px;

              .left-blog-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 20px;
                border: 0.8px solid var(--light-shadow);
              }
            }

            .left-blog-details-container {
              .left-blog-title-container {
                margin: 1rem 0;

                .left-blog-title {
                  line-height: 2rem;
                  color: var(--text-primary);
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .description-container {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .left-blog-description-container {
                  .left-blog-description {
                    color: var(--text-grey);
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .separator-line {
                  border-bottom: 1px solid var(--light-shadow);
                }

                .bottom-container {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;

                  .date-container,
                  .views-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 7px;

                    .date,
                    .views {
                      color: var(--text-grey);
                      font-size: 0.9rem;
                    }
                  }
                }
              }
            }
          }

          // .column-gapping {

          .blog-card {
            .img-outer {
              height: 175px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
              }
            }
            .info {
              padding: 10px;
              h5 {
                font-size: 18px;
              }
              p {
                font-size: 14px;
                margin-bottom: 7px;
              }
              .readmore {
                a {
                  padding: 10px;
                }
              }
            }
          }
          // }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .resourcecenter {
    .gallery-images {
      .imgimg {
        width: 48%;
        height: 150px;
      }
    }
  }
}
