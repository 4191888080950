.main-container {
  padding: 5rem 0;
  background: var(--bg-light);

  .top-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;

    .form-con {
      width: 40%;
      height: 100%;

      .data-collect-div {
        height: 70%;
        width: calc(100% - 30%);
        width: 380px;
        border-radius: 18px;
        margin: auto;
        background: var(--bg-tertiary);
        color: var(--text-secondary);
        padding: 3rem 40px;
        position: relative;

        .header {
          width: 100%;

          h1 {
            text-align: center;
            font-size: 2.3rem;
            font-weight: 600;

            .touch {
              background: linear-gradient(
                88.23deg,
                #097dc6 36.18%,
                #51aae2 115.5%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          p {
            margin-top: 10px;
            font-size: 12px;
            line-height: 1rem;
            text-align: center;
            color: var(--text-light-grey);
          }
        }

        .dropMail {
          margin-top: 1.2rem;

          .input-box {
            margin-top: 1rem;
          }

          input {
            width: 100%;
            border-radius: 7px;
            border: none;
            padding: 7px 1px;
            font-weight: 500;
            padding-left: 11px;
          }
          input::placeholder {
            color: gray;
            font-size: 14px;
          }
          input:focus {
            border: none;
            outline: none;
          }

          .dropdown {
            select {
              width: 100%;
              padding: 9px 8px;
              color: gray;
              font-size: 14px;
              font-weight: 500;

              border-radius: 7px;
            }
          }
          .right-container {
            .search-box {
              overflow: hidden;
              .input-box {
                width: 100%;
                border-radius: 6px;
                color: gray;
                font-size: 14px;
                font-weight: 500;
                padding: 2px 8px;
              }
            }

            .empty-message {
              text-align: center;
              color: gray;
              font-size: 14px;
              font-weight: 500;
              padding: 10px;
              background: #f8f9fa;
              border-radius: 6px;
            }

            .selected-products {
              background: white;
              border-radius: 6px;
              margin-top: 5px;
              .selected-product {
                padding: 2px 8px;
                display: flex;
                justify-content: space-between;
                span {
                  display: inline-block;
                  color: gray;
                  font-size: 14px;
                  font-weight: 500;
                }
                button {
                  text-align: end;
                }
              }
            }
          }

          textarea {
            width: 100%;
            height: 5rem;
            border-radius: 7px;
            padding: 9px;
            font-family: Poppins;
          }

          .button {
            button {
              background: var(--bg-tertiary);
              background: linear-gradient(
                88.23deg,
                #097dc6 36.18%,
                #51aae2 115.5%
              );
              border: none;
              color: white;
              padding: 10px 15px;
              border-radius: 7px;
              cursor: pointer;
              width: 100%;
            }
          }
        }
      }
    }

    .Info-con {
      width: 57%;
      height: 100%;

      .ContHead {
        color: var(--text-grey);
        font-weight: 400;
        text-transform: uppercase;
      }

      .con-para {
        width: 80%;
        font-size: 3rem;
        // line-height: 59px;
        font-weight: 400;
        color: var(--text-primary);

        .answer {
          color: var(--bg-secondary);
        }
      }

      .conInfo {
        display: flex;
        margin-top: 3rem;
        .contact-left,
        .contact-right {
          width: 50%;
        }
        .contact-left {
          .phoneNum {
            h5 {
              font-weight: 500;
            }
            p {
              margin-top: 7px;
              color: var(--text-primary-light);
            }
          }
          .location {
            width: 60%;
            margin-top: 1rem;

            h5 {
              font-weight: 600;
            }

            p {
              margin-top: 7px;
              font-size: 1rem;
              color: var(--text-primary-light);
              font-weight: 400;
              line-height: 24px;
            }
          }

          .icons {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-top: 1.3rem;

            h5 {
              font-weight: 600;
            }

            .icon {
              display: flex;
              gap: 15px;

              .social-icon {
                transition: all 0.3s ease;
                cursor: pointer;
              }

              .linkedin {
                color: var(--linkedin); /* LinkedIn color */
              }

              .facebook {
                color: var(--facebook); /* Facebook color */
              }

              .twitter {
                color: #0c0c0c; /* Twitter color */
              }

              .social-icon:hover {
                transform: scale(1.2);
              }
            }
          }
        }

        .contact-right {
          .mails {
            h5 {
              font-weight: 600;
            }

            p {
              margin-top: 7px;
              color: var(--text-primary-light);
              font-weight: 400;
            }
          }
          .mail {
            margin-top: 1.2rem;

            h5 {
              font-weight: 600;
            }

            p {
              margin-top: 7px;
              color: var(--text-primary-light);
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .main-container {
    .top-container {
      justify-content: space-between;
      margin-top: 2rem;

      .form-con {
        width: 40%;
        position: relative;

        .data-collect-div {
          width: 90%;
          padding: 60px 30px;
        }
      }

      .Info-con {
        .con-para {
          font-size: 40px;
          line-height: 3rem;
        }
      }
    }
  }
}

/* Responsive design for mobile */
// @media (max-width: 768px) {
//   .main-container {
//     .top-container {
//         align-items: start;
//         margin-top: 1rem;

//       .form-con {
//         width: 40%;
//         margin-top: 1.5rem;
//         position: relative;
//         .data-collect-div {
//           width: 90%;
//           padding: 30px 22px;
//         }
//       }

//       .Info-con {
//         .con-para {
//           font-size: 40px;
//           line-height: 2.5rem;
//         }
//       }
//     }
//   }
// }

@media (max-width: 868px) {
  .main-container {
    padding: 1rem 0;
    border: 1px solid rgba(255, 0, 0, 0);
    .top-container {
      flex-direction: column-reverse;
      align-items: center;
      margin-top: 1rem;

      .form-con {
        width: 80%;
        margin-top: 2rem;
        position: relative;
        .data-collect-div {
          width: 80%;
        }
      }

      .Info-con {
        width: 80%;
        .ContHead {
          text-align: center;
          margin-bottom: 10px;
        }
        .con-para {
          line-height: 2rem;
          font-size: 2rem;
          width: 90%;
        }
        .conInfo {
          margin-top: 1rem;
        }
      }
    }
  }
}

//   /* Responsive design for very small devices */
@media (max-width: 500px) {
  .main-container {
    .top-container {
      .form-con {
        width: 95%;
        .data-collect-div {
          width: 90%;
          padding: 45px 35px;
          .header {
            h1 {
              font-size: 1.6rem;
            }
          }
          .dropMail {
            margin-top: 0.5rem;
            .input-box {
              margin-top: 0.7rem;
            }
            input {
              padding: 3px 0 3px 11px;
            }
            .dropdown {
              select {
                font-weight: 500;
                padding: 3px 8px;
              }
            }
            textarea {
              padding: 3px 11px;
              font-size: 14px;
              font-weight: 500;
            }
            .button {
              button {
                padding: 8px 15px;
                font-size: 14px;
              }
            }
          }
        }
      }

      .Info-con {
        width: 90%;
        .ContHead {
          font-size: 1rem;
        }
        .con-para {
          width: 100%;
          font-size: 1.5rem;
          line-height: 29px;
        }

        .conInfo {
          margin-top: 1rem;
          flex-direction: column;
          .contact-left,
          .contact-right {
            width: 100%;
          }
          .contact-left {
            .location {
              width: 100%;
              p {
                font-size: 14px;
              }
            }
          }
          .contact-right {
            margin-top: 1.5rem;
            .mails {
              margin: 0;
              h6 {
                margin: 0;
              }
              p {
                margin: 0;
              }
            }
            .mail {
              margin-top: 10px;
              h6 {
                margin: 0;
              }
              p {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
