.single-product {
  padding: 3rem 0rem;

  .productName {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-primary);
    padding-left: 3.7rem;
    line-height: 1px;

    span {
      color: var(--text-primary-light);
    }
  }

  .hrline {
    width: 35%;
    margin-left: 3.7rem;
    border: 1px solid #13131333;
  }

  .top-section {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // align-items: center;
    background: var(--bg-light);
    border-radius: 15px 15px 0 0;
    padding: 12px 2.5rem 12px 12px;

    .carousel-container {
      width: 50%;
      height: 100%;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .All_Image {
        z-index: 3;
        gap: 20px;
        display: flex;
        flex-direction: column;

        .gallery-image {
          height: fit-content;

          .single-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .single-image {
        height: 100%;
        width: 80%;
        z-index: 2;
        object-fit: contain;
        border-radius: 10px;
        filter: drop-shadow(3px 3px 20px rgba(255, 255, 255, 0.6));
      }
    }

    .right-container {
      position: relative;
      width: 45%;
      border-radius: 15px;
      // display: flex;
      // align-items: center;

      .details-container {
        border: 1px solid var(--border);
        border-radius: 15px;
        padding: 1rem;
        position: relative;
        z-index: 2;
        background: var(--bg-light);

        .category {
          color: var(--text-theme);
        }

        .product-name {
          font-weight: 700;
          line-height: 1.2rem;
        }

        .about-product {
          // margin-top: 1rem;
          color: var(--text-primary);
        }

        .btn-container {
          margin-top: 2rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          .single-btn {
            // width: 13.5rem;
            width: 50%;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .shadow {
        justify-self: center;
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        height: 7px;
        width: 90%;
        background: var(--light-shadow);
        opacity: 0.5;
        border-radius: 0 0 10px 10px;
        filter: blur(10px);
        box-shadow: 0px 3px 20px var(--light-shadow);
        z-index: 1;
      }
    }
  }
  .dialog-container {
    position: fixed;
    top: 0%;
    left: 0%;
    transition: transform(-50%, -50%);
    z-index: 9;
  }

  .description-container {
    z-index: 3;
    background: var(--bg-primary);
    border-radius: 10px;
    position: relative;
    width: 92%;
    margin: auto;
    .all-tab-heading {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 1rem 0 4rem;
      border: 1px solid #1313131a;
      border-radius: 15px;
      box-shadow: 0 -5px 20px rgba(0, 0, 0, 0.178);

      .tab-headings {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0 0rem 0;

        .tab-title {
          cursor: pointer;
          color: var(--text-grey);
          font-weight: 400;
          padding-bottom: 1.3rem;
          z-index: 2;

          &:hover {
            color: var(--bg-secondary);
          }
        }

        .weight {
          font-size: 14px;
          font-weight: 600;
          color: var(--text-primary-light);
          background: var(--bg-secondary);
          padding: 2px 10px;
          border-radius: 5px;
          color: white;
        }

        .tab-title.active {
          font-weight: 600;
          color: var(--bg-secondary);
          border-bottom: 3px solid var(--bg-secondary);
        }
      }

      .bottom-border {
        width: 100%;
        border-top: 3px solid var(--border);
        position: absolute;
        bottom: 0;
        z-index: 1;
      }
    }

    .tab-details-container {
      width: 90%;
      margin: auto;
      padding: 1.5rem 0;

      .all-description-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .video-container {
          position: relative;
          padding-bottom: 35.25%;
          height: 0;
          overflow: hidden;
          max-width: 100%;
        }

        .video-container iframe {
          position: absolute;
          top: 0;
          left: 18%;
          width: 65%;
          height: 100%;
          @media (max-width: 550px) {
            width: 100%;

            left: 0;
          }
        }
      }

      .features-and-generals {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 5rem;

        .features-section {
          display: flex;
          flex-direction: row;
          gap: 5rem;

          .single-feature {
            display: flex;
            flex-direction: column;
            gap: 0.7rem;

            .feature-details-text {
              color: var(--text-grey);
            }
          }
        }

        .generals-section {
          ul {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }
        }
      }

      .instructions-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .instructions {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }
  }

  .related-products-container {
    margin-top: 3rem;
    padding: 0 3rem;
  }
}

@media (max-width: 1325px) {
  .single-product {
    .top-section {
      width: 95%;
      padding: 12px;

      .right-container {
        .details-container {
          padding: 1rem;

          .product-name {
            font-size: 2.3rem;
            line-height: 2.3rem;
          }
        }
      }
    }

    .description-container {
      width: 95%;
    }
  }
}

@media (max-width: 1200px) {
  .single-product {
    .description-container {
      width: 95%;
    }
  }
}
@media (max-width: 1100px) {
  .single-product {
    .top-section {
      .right-container {
        .details-container {
          .product-name {
            font-size: 2rem;
            line-height: 2.3rem;
          }
        }
      }
    }

    .description-container {
      width: 95%;
      .all-tab-heading {
        padding: 0;
        .tab-headings {
          justify-content: space-around;
          gap: 0;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .single-product {
    .top-section {
      position: relative;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      flex-direction: column;
      border-radius: 15px;

      .carousel-container {
        width: 100%;
        height: 450px;

        .All_Image {
          gap: 5px;
          height: 100%;
          width: 15%;
          overflow: scroll;
        }
        .single-image {
          // border: 1px solid red;
          height: 100%;
          width: 100%;
          // object-fit: fill;
        }
      }
      .right-container {
        width: 100%;
      }
    }
  }
}
@media (max-width: 630px) {
  .single-product {
    padding-top: 1rem;
    .productName {
      padding-left: 1.5rem;
      padding-bottom: 10px;
    }
    .hrline {
      margin-left: 1.5rem;
      margin: 8px;
    }
    .top-section {
      gap: 10px;
      .carousel-container {
        width: 100%;
        height: 100%;
        flex-direction: column-reverse;

        .All_Image {
          gap: 15px;
          height: 100%;
          width: 60%;
          overflow: scroll;
          flex-direction: row;
        }
        .single-image {
          height: 100%;
          width: 100%;
        }
      }
      .right-container {
        width: 100%;
        .details-container {
          padding: 1rem;

          .category {
            font-size: 14px;
            // border: 1px solid red;
          }
          .product-name {
            margin-top: 10px !important;
            line-height: 1.4rem !important;
            font-size: 1.2rem;
          }
          .about-product {
            font-size: 14px;
          }
        }
      }
    }
    .description-container {
      .all-tab-heading {
        .tab-headings {
          position: relative;
          .tab-title {
            font-size: 14px;
          }
          .weight {
            position: absolute;
            right: 3%;
            top: -20%;
          }
        }
      }
      .tab-details-container {
        width: 100%;
        text-align: justify;
        padding: 14px;

        .all-description-container {
          gap: 12px;
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .single-product {
    .top-section {
      padding: 0;
      .carousel-container {
        width: 100%;
        // height: 70vh;
        flex-direction: column-reverse;

        .All_Image {
          gap: 10px;
          // height: 20%;
          // width: 100%;
          overflow: scroll;
          flex-direction: row;
          .gallery-image {
            // height: 80%;
          }
        }
        .single-image {
          // height: 80%;
          // width: 100%;
        }
      }
      .right-container {
        .details-container {
          padding: 10px;
          .product-name {
            line-height: 1rem;
            font-size: 1.5rem;
          }
          .about-product {
            text-align: justify;
          }
          .btn-container {
            margin-top: 1rem;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5px;
            .single-btn {
              width: 100%;
              .batan {
                padding: 5px 2px !important;
              }
            }
          }
        }
      }
    }
    .description-container {
      .all-tab-heading {
        padding: 0 10px;
        .tab-headings {
          gap: 10px;
          text-align: center;
          .tab-title {
            font-size: 14px;
          }
        }
      }
      .tab-details-container {
        width: 100%;
        text-align: justify;
        padding: 14px;
        font-size: 14px;
        padding: 10px;
        .all-description-container {
          gap: 12px;

          p {
            font-size: 14px;
          }
        }
        .features-and-generals {
          .generals-section {
            ul {
              gap: 10px;
            }
          }
        }
      }
    }
    .related-products-container {
      padding: 1rem;
    }
  }
}

@media (max-width: 370px) {
  .single-product {
    .description-container {
      width: 100%;
      padding: 5px;
      .all-tab-heading {
        padding: 0 10px;
        .tab-headings {
          gap: 5px;
          text-align: center;
          .tab-title {
            font-size: 10px;
          }
        }
      }
    }
  }
}
