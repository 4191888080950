// Styles for the OilEmulsifier component
.oil-emulsifier {
  font-family: Arial, sans-serif;
  width: 95%;
  margin: auto;
  padding: 2rem 1.5rem;
  background-image: url("../../../assets/images/Ellipse\ 21.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 25px;
  @media (max-width: 600px) {
   padding: 1rem;
  }
  .oil-con {
    background: white;
    padding: 1.5rem;
    border-radius: 30px;
    margin-top: 10px;
    .oil-head {
      color: #2c3e50;
      text-align: center;
      margin-bottom: 20px;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .oil-title {
      color: #2980b9;
      font-size: 1.8rem;
      font-weight: 700;
      background: linear-gradient(90deg, #097dc6, #51aae2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      width: fit-content;
      @media (max-width: 600px) {
        font-size: 1.2rem;
      }
    }

    .oil-para {
      font-size: 16px;
      line-height: 1.5;
      color: #34495e;
    }

    ul {
      list-style-type: decimal;
      padding-left: 20px;
      margin-top: 10px;

      li {
        font-size: 16px;
        line-height: 1.5;
        color: #131313a6;
        margin-bottom: 10px;
        line-height: normal;
        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
  }
  .oil-con:nth-child(1) {
    display: none;
  }
}
