.benefits-section {
  background-image: url("../../assets/Product/bg.jpeg");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;

  .innerSection {
    height: 100%;
    width: 100%;
    background-blend-mode: overlay;
    background: linear-gradient(250.78deg, #0086d9b9 2.75%, #004773d5 100.17%);
    display: flex;
    justify-content: center;
    align-items: center;

    .cardSection {
      height: fit-content;
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 0 3rem;

      .cards {
        background: white;
        border-radius: 10px;
        flex: 0 0 calc(33% - 5rem);
        height: 500px;
        padding: 2rem;
        transition: all 0.4s ease-in-out;

        &:hover {
          transform: scale(1.03);
        }

        .icon {
          width: 75px;
          height: 75px;
        }

        h2 {
          margin-top: 1.2rem;
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 1.8rem;
          background: linear-gradient(
            101.36deg,
            #2aa6f4 -9.57%,
            #0376bf 80.49%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent; /* For WebKit browsers */
          background-clip: text;
          color: transparent;
        }

        ul {
          margin-top: 2rem;
          padding: 0;

          li {
            margin-top: 10px;
            list-style: none;
            font-weight: 400;
            font-size: 0.8rem;
            line-height: 18px;
            display: flex;
            gap: 10px;

            .icons {
              margin-top: 1px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .benefits-section {
    .innerSection {
      .cardSection {
        justify-content: space-between;

        .cards {
          flex: 0 0 calc(33% - 2rem);
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .benefits-section {
    .innerSection {
      .cardSection {
        margin: 0 2rem;

        .cards {
          flex: 0 0 calc(33% - 1rem);
          padding: 1.5rem;

          .icon {
            width: 50px;
            height: 50px;
          }

          h2 {
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .benefits-section {
    .innerSection {
      .cardSection {
        .cards {
          flex: 0 0 calc(33% - 10px);

          h2 {
            font-size: 1.2rem;
            line-height: 1.5rem;
          }

          ul {
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .benefits-section {
    height: 100%;

    .innerSection {
      .cardSection {
        flex-direction: column;
        gap: 1rem;
        margin: 2rem;

        .cards {
          width: 100%;

          .icon {
            width: 60px;
            height: 60px;
          }

          h2 {
            font-size: 1.5rem;
            line-height: 1.8rem;
          }

          ul {
            margin-top: 1.3rem;

            li {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .benefits-section {
    .innerSection {
      .cardSection {
        margin: 1rem;

        .cards {
          .icon {
            width: 55px;
            height: 55px;
          }

          h2 {
            margin-top: 1rem;
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
