.primary-btn {
  cursor: pointer;
  text-align: center;
  border-radius: 10px;

  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 600;
  height: 100%;
  // width: 13.5rem;
  width: 90%;
}

@media (max-width: 480px) {

  .primary-btn{
    width: 100%;
    font-size: 14px;
    padding: 10px 5px !important;
  }
}
