.navbar {
  background: var(--bg-tertiary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 2.5rem;

  .nav-links {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    position: relative;

    .nav-link {
      color: var(--text-secondary);
      font-size: 0.9rem;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        color: var(--text-theme);
      }
    }
    // Dropdown menu
    .dropdown {
      position: absolute;
      top: 99%;
      background-color: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      z-index: 1000;
    }

    .dropdown-link {
      padding: 0.2rem 1rem;
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 600;
    }

    .dropdown-link:hover {
      background-color: #f0f0f0;
      color: blue;
    }
  }

  .ham {
    display: none;
  }
  .mobile-outer {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar {
    .nav-links {
      display: none;
    }
    .ham {
      display: contents;
      color: white;
      font-size: 35px;
      font-weight: 500;
    }
    .mobile-outer {
      display: contents;
      height: 100vh !important;
      width: 100vw !important;

      .nav-links-mobile {
        z-index: 9999999;
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 20px 40px;
        position: absolute !important;
        top: 100%;
        right: 0;
        background: var(--bg-tertiary);
        transition: all 1s linear;
        .nav-item {
          .nav-link {
            color: var(--text-secondary);
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;

            &:hover {
              color: var(--text-theme);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 0.8rem 1rem;

    .nav-links-mobile {
      width: 60%;
    }
  }
}
